/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../css/tailwind.css";
import "../css/lightbox.css";
require("local-time").start();
// import "chartkick/chart.js";
import flatpickr from "flatpickr";
import AOS from 'aos';
import "aos/dist/aos.css";
require("flatpickr/dist/flatpickr.css");
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
//fullcalendar
import moment from 'moment'
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import "trix"
import "@rails/actiontext"
// import './config_chart.js'
import Chart from 'chart.js/auto';
import SlimSelect from "slim-select";
import 'slim-select/dist/slimselect.css';

window.Chart = Chart
window.SlimSelect = SlimSelect
window.flatpickr = flatpickr;
window.rangePlugin = rangePlugin;
window.dayGridPlugin = dayGridPlugin;
window.Calendar = Calendar;
window.moment = moment;
window.aos = AOS;

require("trix")
require("@rails/actiontext")
